.ticket-welcome-main-container {
  background: #B8FB04;
  margin-top: -1rem;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket-welcome-main-container .join-button {
  background-color: #fd7e14;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.welcome-creator-profile1 {
  width: 3.5rem;
  height: 3.5rem;
  border: 2px solid #212529;
  border-radius: 50%;
}

.welcome-creator-profile {
  width: 3.5rem;
  height: 3.5rem;
  border: 2px solid #212529;
  border-radius: 50%;
  margin-left: -25px;
}

.creator-image {
  width: 100%;
  height: 12rem;
}

.discover-heading {
  font-weight: 300 !important;
  font-size: 2.5rem;
}

.features {
  color: #fff;
  cursor: pointer;
  border-right: 1px solid #c8c9ca48;
}

.features:hover {
  background-color: #B8FB04;
  color: #000;
  box-shadow: -10px -10px 0px #00d7d2, 10px 10px 0px #ff029a;
}

.feature-description {
  max-height: 8rem;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.countdown {
  background-color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-family: monospace;
  /* background: #f8d4d4; */
  border: 1px solid #000;
}

.start-bid-button {
  background: #212529;
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  border: 2px solid #212529;
}

.start-bid-button:hover {
  background-color: #B8FB04;
  border: 2px solid #212529;
  box-shadow: 6px 6px 0px #212529;
  color: #212529;
}

::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}


/* Newsletter section */

.newsletter-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  /* background-image: url('../../img/concert.jpg');
  background-size: cover;
  position: relative;
  backdrop-filter: opacity(10%); */
}

.newsletter-heading {
  font-size: 3rem !important;
  font-weight: 900 !important;
  text-transform: uppercase;
  color: #00000042 !important;
  text-shadow: 4px 4px 0px #B8FB04;
  /* text-shadow: 3px 3px 0px #b9fb04e2; */
}

.newsletter-tagline {
  text-transform: capitalize;
  font-weight: 400 !important;
  font-size: 1rem;
  color: #606061;
}

.newsletter-email-field {
  border-bottom: 1px solid !important;
  border-radius: 0px !important;
}

.subscirbe-newsletter-section {
  /* position: absolute; */
  /* top: 100; */
  /* left: 0; */
  /* left: 1;
  right: 10; */
  /* max-width: 97vw; */
  /* background-color: rgba(2, 57, 14, 0.201); */
  padding: 18rem;
  backdrop-filter: blur(2px);
  /* filter: brightness(1); */
}

.newsletter-side-section {
  /* backdrop-filter: opacity(100%); */
  background-color: #B8FB04;
}

.subscirbe-newsletter-section input:focus,
.subscirbe-newsletter-section input:hover {
  border: none !important;
  border-bottom: 1px solid #000 !important;
}

.subscribe-btn {
  text-transform: uppercase;
  border: 2px solid #000000b0;
  background-color: #B8FB04;
  /* box-shadow: 10px 10px 15px 0px #000000b0; */
  box-shadow: 7px 7px 0px #000000b0;
}

.subscribe-btn:hover {
  border: 2px solid #000000b0;
  background-color: #B8FB04;
  box-shadow: -7px -7px 0px #000000b0;
}

.newletter-container {
  border: 2px solid #c8c9ca;
  border-radius: 20px;
  box-shadow: 0px 5px 5px 0px #c8c9caa9;
}

.newsletter-img {
  width: 110%;
  height: 120%;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  backdrop-filter: brightness(100%);
}

.newsletter-form-container {
  border: 1px solid #B8FB04
}

.newsletter-form-input {
  color: #fff !important;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid transparent !important;
  border-radius: 0 !important;
}

.newsletter-form-input:focus {
  border-bottom: 1px solid #fff !important;
}

.newsletter-form-btn {
  background-color: #0000002e;
  border: none;
  color: #000000b0 !important;
  background-color: #B8FB04;
  border-radius: 50px;
}

.newsletter-form-btn:hover {
  transition: 0.3s all ease-in-out;
  border-radius: 0px;
  box-shadow: 5px 5px 0px #00dfff;
}

.newsletter-main {
  background-image: url('../img/concert.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.newsletter-main-container {
  background-color: #000000ad;
  backdrop-filter: blur(2px);
}


/* Ticket events */

.ticket-events {
  height: 75vh;
  /* background-color: #ff; */
}

.ticket-event-heading {
  color: blue;
  font-size: 3.5rem !important;
  text-decoration: none;
  text-transform: uppercase;
  /* text-shadow: 7px 4px #f228ff; */
  /* color:  !important; */
}

.ticket-event-heading:hover {
  text-shadow: 7px 4px #f228ff;
}

.ticket-event-subheading {
  text-transform: uppercase;
  color: blue;
  font-size: 1.2rem;
}

/* .create-event-btn{
  border: 0;
  background-color: blue;
  color: #fff;
  border: 2px solid transparent;
} */

.create-event-btn {
  font-size: 1.2rem;
  color: #fff;
  text-transform: uppercase;
  /* padding: 10px 20px; */
  border-radius: 10px;
  background: blue;
  box-shadow: 7px 7px #f228ff;
  cursor: pointer;
  margin: 35px 0;
  border: 2px solid transparent;
  transition: all 0.1s ease-in-out;
}

.create-event-btn:hover {
  box-shadow: -7px -7px #f228ff;
  transform: translate(3px, 3px);
  border-radius: 0;
}

.event-ticket-desc {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: Thin !important;
  /* font-weight: lighter !important; */
  color: #000;
}

/* .create-event-btn:hover{
  background-color: #B8FB04;
  box-shadow: 8px 8px 0 #6ecdff;
  color: #000;
  border: 2px solid #6ecdff;
} */

/* .ticket-event-side-container {}

.ticket-img {
  width: 100%;
  height: 100%;
} */